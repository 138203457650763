































import { Component, Prop } from "vue-property-decorator";
import BaseEntryGrid from "@/components/global/grid/BaseEntryGrid";
import AnimeTooltip from "@/common/tooltips/AnimeTooltip";
import GridCardComponent from "@/components/global/grid/GridCardComponent.vue";
import { UserListEntry } from "@sokkuri/common";

@Component({
    components: {
        GridCardComponent
    }
})
export default class AnimeUserListGridComponent extends BaseEntryGrid {
    @Prop() protected entries: UserListEntry[];

    protected entryType = "anime";

    /*
        ToDo: Investigate later
        The watch for the entries here don't get trigger on a change.
        I think it is because the entries come from a for and this doesn't trigger the watch.
        So I init the tooltips manually here.
    */
    mounted() {
        this.initTooltips();
    }

    protected initTooltips() {
        const elements = this.$el.querySelectorAll("div.grid-card-component div.tooltip");

        if (elements) {
            new AnimeTooltip(this.entries.map(x => x.anime)).createMany(elements);
        }
    }
}


























import { Component, Vue } from "vue-property-decorator";
import TranslationUtils from "@/common/utilities/TranslationUtils";
import ProfileBaseView from "@/views/account/profile/ProfileBaseView.vue";
import AnimeUserListGridComponent from "@/components/global/grid/AnimeUserListGridComponent.vue";
import UserListDataContext from "@/dataContexts/UserListDataContext";
import StringUtils from "@/common/utilities/StringUtils";
import KeyValuePair from "@/common/models/KeyValuePair";
import { UserListEntry, Constants } from "@sokkuri/common";

@Component({
    components: {
        ProfileBaseView,
        AnimeUserListGridComponent
    }
})
export default class AnimeListView extends Vue {
    private userListDataContext = new UserListDataContext();

    private loading = false;

    private entries: KeyValuePair<string, UserListEntry[]>[] = [];

    created() {
        const userName = this.$route.params.userName;

        this.loading = true;

        this.userListDataContext.getAnimeEntries(userName).then(x => {
            if (x.successfully && x.data) {
                this.mapEntries(x.data);
            }
        }).finally(() => this.loading = false);
    }

    private mapEntries(entries: UserListEntry[]) {
        Constants.UserList.EntryState.AnimeStates.forEach(state => {
            const filteredEntries = entries.filter(x => StringUtils.equalsIgnoreCase(x.status, state));
            this.entries.push(new KeyValuePair<string, UserListEntry[]>({ key: TranslationUtils.translate(state), value: filteredEntries }));
        });
    }
}
